import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers } from '../../api';

const MemberList = ({ members, onDelete, setSelectedItems }) => {

  const [boardMap, setBoardMap] = useState({});
  const [playerMap, setPlayerMap] = useState({});

  useEffect(() => {
    // 팀 목록 가져오기
    fetchBoards().then(response => {
        // 팀 ID와 이름 매핑 객체 생성
        const boardMap = response.data.reduce((acc, board) => {
            acc[board.id] = board.name;
            return acc;
        }, {});
        setBoardMap(boardMap);
    }).catch(error => {
        console.error('There was an error fetching the teams!', error);
    });
  }, []);

  useEffect(() => {
      // 게임 목록 가져오기
      fetchPlayers().then(response => {
          // 게임 ID와 이름 매핑 객체 생성
          const playerMap = response.data.reduce((acc, player) => {
              acc[player.id] = player.name;
              return acc;
          }, {});
          setPlayerMap(playerMap);
      }).catch(error => {
          console.error('There was an error fetching the Games!', error);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]); 
  const postsPerPage = 20;

  const sortedToolSettings = members.slice().reverse();


  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = sortedToolSettings.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedToolSettings.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // use_service에서 서비스 이름만 추출하는 함수
  const parseServiceDetails = (useService) => {
    try {
      const parsedService = JSON.parse(useService);
  
      // 이름과 가격을 함께 표시하며 1000단위 쉼표를 추가한 배열을 반환
      const serviceDetails = parsedService.map(service => {
        const formattedPrice = service.price.toLocaleString(); // 1000단위 쉼표 추가
        return `${service.name}`;
      });
  
      return serviceDetails;
    } catch (error) {
      console.error('Error parsing use_service:', error);
      return ['']; // 배열 형태로 반환
    }
  };

  const calculateTotalPrice = (useService) => {
    try {
      const parsedService = JSON.parse(useService);
      // 모든 서비스의 가격을 더함
      const totalPrice = parsedService.reduce((sum, service) => sum + service.price, 0);
      return totalPrice.toLocaleString(); // 1000단위 쉼표 추가
    } catch (error) {
      console.error('Error calculating total price:', error);
      return '0'; // 에러가 발생한 경우 0을 반환
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    let updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id) // 선택 해제
      : [...selected, id]; // 선택 추가

    setSelected(updatedSelected);
    setSelectedItems(updatedSelected); // 부모에게 선택된 항목 전달
  };

  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((member, index) => (
            <li key={member.id}>
              <div className='idx'>
                <input
                  type='checkbox'
                  checked={selected.includes(member.id)}
                  onChange={() => handleCheckboxChange(member.id)}
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='member small'>{member.name}</div> 
              <div className='member small'>{member.identity}</div> 
              <div className='member small'>{member.phone_no}</div> 
              <div className='member small'>{boardMap[member.tax_name] || 'Unknown'}</div>
              <div className='member small'>{playerMap[member.cms_check] || 'Unknown'}</div>
              <div className='member small'>{member.confirm_date}</div> 
              <div className='member'>
                {parseServiceDetails(member.use_service).map((serviceDetail, idx) => (
                  <div key={idx}>{serviceDetail}</div>
                ))}
              </div>
              <div className='member small'>
                {calculateTotalPrice(member.use_service)}원
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default MemberList;