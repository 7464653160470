import React from 'react';
import './layout/layoutClient.css';

const Modal = ({ isOpen, message, onClose, onConfirm }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <p>{message}</p>
          <div className="modal-buttons">
            <button onClick={onConfirm} className='confirm'>확인</button>
            <button onClick={onClose} className='delete'>취소</button>
          </div>
        </div>
      </div>
    );
  };
  
  export default Modal;