import React, { useState, useEffect } from 'react';
import { fetchToolSettings } from '../../api';

import Layout from '../../components/layout/Layout';
import ToolSettingForm from '../../components/toolSetting/ToolSettingForm';

const App = () => {
  const [toolSettings, setToolSettings] = useState([]);
  const [currentToolSettingId, setCurrentToolSettingId] = useState(null);

  const handleSaveToolSetting = () => {
    fetchToolSettings().then((response) => {
      setToolSettings(response.data);
    }).catch(error => {
      console.error('There was an error fetching the toolSetting!', error);
    });
    setCurrentToolSettingId(null);
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>서비스 관리</div>
        <div className='single-form-wrap'>
          <ToolSettingForm onSave={handleSaveToolSetting} />
        </div>
      </div>
    </>
  );
};

export default App;