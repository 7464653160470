import React, { useState } from 'react';
 
const ToolSettingList = ({ toolSettings, onEdit, onDelete, setSelectedItems }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]); 
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = toolSettings.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(toolSettings.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // 숫자를 천 단위로 포맷팅하는 함수
  const formatPrice = (price) => {
    return new Intl.NumberFormat().format(price);
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    let updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id) // 선택 해제
      : [...selected, id]; // 선택 추가

    setSelected(updatedSelected);
    setSelectedItems(updatedSelected); // 부모에게 선택된 항목 전달
  };

  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((toolSetting, index) => (
            <li key={toolSetting.id}>
              <div className='idx'>
                <input
                  type='checkbox'
                  checked={selected.includes(toolSetting.id)}
                  onChange={() => handleCheckboxChange(toolSetting.id)}
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='toolsettings' onClick={() => onEdit(toolSetting.id)}>
                {toolSetting.name}
              </div>
              <div className='toolsettings'>{formatPrice(toolSetting.price)}원</div>
              <div className='controll'>
                <button onClick={() => onEdit(toolSetting.id)}>수정</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>

    </>
  );
};

export default ToolSettingList;