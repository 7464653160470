import React, { useState, useEffect } from 'react';
import { fetchUsers, deleteUser, fetchBoards, fetchPlayers } from '../../api';

import Layout from '../../components/layout/Layout';
import UserList from '../../components/member/MemberList';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const MemberList = () => {
  const [users, setUsers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [searchName, setSearchName] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    loadUsers();
    loadBoards();
    loadPlayers();
  }, []);

  const loadUsers = () => {
    fetchUsers().then((response) => {
      setUsers(response.data);
      setFilteredUsers(response.data);
    }).catch((error) => {
      console.error("Error loading users:", error);
    });
  };

  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadPlayers = () => {
    fetchPlayers().then(response => {
      setPlayers(response.data);
    }).catch(error => {
      console.error('Error loading games:', error);
    });
  };

  const handleDeleteUser = (id) => {
    if (window.confirm('해당 업체 정보를 삭제하시겠습니까?')) {
      deleteUser(id).then(() => {
        loadUsers();
      }).catch((error) => {
        console.error("Error deleting toolSetting:", error);
      });
    };
  };

  // 선택된 항목을 삭제하는 함수
  const handleDeleteSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('업체정보를 선택해주세요.');
    } else {
      if (window.confirm('선택한 업체 정보들을 삭제하시겠습니까?')) {
        Promise.all(selectedItems.map(id => deleteUser(id))) // 모두 삭제
        .then(() => {
          loadUsers();
          setSelectedItems([]); // 선택된 항목 초기화
        })
        .catch(error => {
          console.error("Error deleting selected toolSettings:", error);
        });
      }
    }
  };

  const handleFilter = () => {
    const newFilteredUsers = users.filter(user => {
      const matchBoard = selectedBoard === '' || user.tax_name === selectedBoard;
      const matchPlayer = selectedPlayer === '' || user.cms_check === selectedPlayer;
      const matchName = searchName === '' || user.name.toLowerCase().includes(searchName.toLowerCase());
      return matchBoard && matchPlayer && matchName;
    });
    setFilteredUsers(newFilteredUsers);
  };

  const exportToExcel = () => {
  
    // 데이터를 엑셀 형식으로 변환
    const ws = XLSX.utils.json_to_sheet(filteredUsers.map(user => {
      const boardName = boards.find(board => board.id === parseInt(user.tax_name))?.name || 'Unknown';
      const playerName = players.find(player => player.id === parseInt(user.cms_check))?.name || 'Unknown';
       // 서비스 이름과 가격 추출 및 총 가격 계산
      const services = JSON.parse(user.use_service);
      const serviceDetails = services.map(service => `${service.name} / ${service.price.toLocaleString()}원`).join(', ');
      const totalServicePrice = services.reduce((sum, service) => sum + service.price, 0);

      return {
        'No': user.id,
        '상호': user.name,
        '연락처': user.phone_no,
        '관리지점': boardName,
        '신청구분': playerName,
        '신청완료일': user.confirm_date,
        '신청서비스': serviceDetails,
        '서비스 총액': totalServicePrice.toLocaleString() + '원',
      };
    }));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '수임동의리스트');
  
    // 엑셀 파일 다운로드
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(file, 'agree-list.xlsx');
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>수임동의 업체 리스트</div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedBoard} onChange={(e) => setSelectedBoard(e.target.value)}>
              <option value=''>관리지점 선택</option>
              {boards.map(board => (
                <option key={board.id} value={board.id}>{board.name}</option>
              ))}
            </select>
            <select value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.target.value)}>
              <option value=''>신청구분 선택</option>
              {players.map(player => (
                <option key={player.id} value={player.id}>{player.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
          <div className='search'>
            <input
              type="text"
              placeholder="사업장 상호"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <button onClick={handleFilter}></button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {users.length}</div>
          <div className='export-button'>
            <button onClick={handleDeleteSelectedItems} className='delete'>
                선택삭제
            </button>
            <button onClick={exportToExcel}>엑셀 다운로드</button>
          </div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>선택</div>
            <div className='idx'>No</div>
            <div className='member small'>상호</div> 
            <div className='member small'>사업자등록번호<br/>{'(생년월일)'}</div> 
            <div className='member small'>연락처</div> 
            <div className='member small'>관리지점</div> 
            <div className='member small'>신청구분</div>
            <div className='member small'>신청완료일</div>
            <div className='member'>추가서비스</div>
            <div className='member small'>수수료</div>
          </div>
          <UserList 
            members={filteredUsers} 
            onDelete={handleDeleteUser} 
            setSelectedItems={setSelectedItems}
          />
        </div>
      </div>
    </>
  );
};

export default MemberList;