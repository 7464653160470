import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers, sendTaxListAuth, sendTaxAuthResult, fetchTaxList } from '../../api';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';

const TaxList = () => {
  const location = useLocation();
  const { userName, identity, phoneNo } = location.state || {};
  var newIdentity = '';
  if (identity) {
    if ( identity.substring(0,1) == 0) {
      newIdentity = '20'+identity.substring(0,6);
    } else {
      newIdentity = '19'+identity.substring(0,6);
    }
  }
  const { shopname, webname } = useParams();
  const [formData, setFormData] = useState({
    organization: "0004",
    loginType: "5",
    loginTypeLevel: "1",
    userName: userName,
    identity: newIdentity,
    phoneNo: phoneNo
  });
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [authResponse, setAuthResponse] = useState(null);  // 간편인증 요청 응답 상태
  const [signedData, setSignedData] = useState(''); // 간편인증 후 사용자가 입력한 서명 데이터
  const [isAuthRequested, setIsAuthRequested] = useState(false); // 인증 요청 상태
  const navigate = useNavigate();

  useEffect(() => {
    loadPlayers();
    loadBoards();
  }, []);

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);
  
    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 간편인증 요청 핸들러
  const handleAuthRequest = async (e) => {
    e.preventDefault();
    setIsAuthRequested(true);
    setErrorMessage('');

    alert('간편인증 요청 중입니다.');

    try {
      const response = await sendTaxListAuth(formData);
      setAuthResponse(response);

      if (response.result.code === 'CF-03002') {
        alert('간편인증이 진행 중입니다. 인증을 완료해주세요.');
      }
    } catch (error) {
      console.error('Error sending auth request:', error);
      alert('간편인증 요청 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    }
  };

  // 간편인증 완료 후 재요청 핸들러
  const handleAuthResult = async () => {
    setErrorMessage('');
    if (!authResponse) {
      alert('먼저 간편인증 요청을 진행해주세요.');
      return;
    }

    setLoading(true);

    try {
      const authData = {
        organization: "0004",
        loginType: "5",
        loginTypeLevel: "1",
        userName: userName,
        identity: newIdentity,
        phoneNo: phoneNo,
        simpleAuth: '1', // 간편인증 방식
        is2Way: true,
        twoWayInfo: {
          jobIndex: authResponse.data.jobIndex,
          threadIndex: authResponse.data.threadIndex,
          jti: authResponse.data.jti,
          twoWayTimestamp: authResponse.data.twoWayTimestamp,
        }
      };

      // 간편인증 완료 후 재요청
      const resultResponse = await sendTaxAuthResult(authData);
      const { result, data } = resultResponse;
      if (result.code === 'CF-00000') {
        let isRegisteredToAnotherFirm = false;
        let currentFirmAgree = false;
        let otherFirmAgree = false;

        console.log(result,data);

        const dataArray = Array.isArray(data) ? data : [data];
      
        for (var i = 0; i < dataArray.length; i++) {
          const firm = dataArray[i];
          console.log(firm.resCompanyIdentityNo, currentBoard.business_no, firm.resInfoProvideAgreeYN)
      
          // 신청한 세무법인 (현재 사업자번호와 일치하는지 확인)
          if (firm.resCompanyIdentityNo == currentBoard.business_no) {
            // 현재 세무법인 동의 여부 확인
            if (firm.resInfoProvideAgreeYN === '동의') {
              currentFirmAgree = true;
              // 동의가 되어있으면 confirmed 페이지로 이동
              navigate(`/apply/${shopname}/${webname}/confirmed`, { state: { resultData: dataArray } });
              return;  // 처리 후 함수 종료
            } else {
              currentFirmAgree = false;
              navigate(`/apply/${shopname}/${webname}/beforeAgree`, {
                state: {
                  resultData: firm,
                  userName: userName,
                  identity: identity,
                  phoneNo: phoneNo
                }
              });
            }
          } else {
            // 다른 세무법인에 이미 등록되어있는지 확인
            isRegisteredToAnotherFirm = true;
      
            // 다른 세무법인의 동의 여부 확인
            if (firm.resInfoProvideAgreeYN === '동의') {
              otherFirmAgree = true;
              // 동의가 되어있으면 dismissal 페이지로 이동
              navigate(`/apply/${shopname}/${webname}/dismissal`, {
                state: {
                  resultData: firm,
                  userName: userName,
                  identity: identity,
                  phoneNo: phoneNo
                }
              });
              return;  // 처리 후 함수 종료
            } else {
              otherFirmAgree = false;
            }
          }
        }
      
        // 만약 다른 세무법인에 등록되어있고, 둘 다 동의가 아닌 경우
        if (isRegisteredToAnotherFirm && !currentFirmAgree && !otherFirmAgree) {
          navigate(`/apply/${shopname}/${webname}/beforeAgree`, { state: { resultData: dataArray } });
        }

        /*if (data[i].resInfoProvideAgreeYN == '동의') { 
              if (data[i].resCompanyIdentityNo == currentBoard.business_no) {
                navigate(`/apply/${shopname}/${webname}/confirmed`, { state: { resultData: data } });
              } else {
                navigate(`/apply/${shopname}/${webname}/dismissal`, { state: { 
                  resultData: data[i],
                  userName: userName,
                  identity: identity,
                  phoneNo: phoneNo
                 } });
              }
            } else {
              if (data[i].resCompanyIdentityNo == currentBoard.business_no) {
                navigate(`/apply/${shopname}/${webname}/beforeAgree`, { state: { resultData: data } });
              } else {

              }
            }*/
      } else {
        setErrorMessage(result.message);
        setIsAuthRequested(false);
      }

    } catch (error) {
      console.error('Error sending auth result:', error);
      alert('인증 중 오류가 발생했습니다. \n 정보를 다시 확인해주세요.');
      setIsAuthRequested(false);
    } finally {
        setLoading(false); // 로딩 종료
    }
  };


  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap'>
            <form onSubmit={handleAuthRequest} className='login-form'>
              <p className='sub-title'>기존 세무대리인 리스트 확인을 위해<br/> 간편인증을 진행해주세요.</p>
                <div className='input-wrap'>
                    <p className='label'>이름</p>
                    {identity ? (
                      <p className='text'>{userName}</p>
                    ):(
                      <input type="text" name="userName" placeholder="대표자명 혹은 개인성명" value={formData.userName} onChange={handleChange} required />
                    )
                    }
                </div>
                <div className='input-wrap'>
                    <p className='label'>생년월일</p>
                    {identity ? (
                      <p className='text'>{newIdentity}</p>
                    ):(
                      <input type="text" name="identity" placeholder="대표자명 혹은 개인성명" value={formData.identity} onChange={handleChange} required />
                    )
                    }
                </div>
                <div className='input-wrap'>
                    <p className='label'>휴대전화번호</p>
                    {identity ? (
                      <p className='text'>{phoneNo}</p>
                    ):(
                      <input type="text" name="phoneNo" placeholder="대표자명 혹은 개인성명" value={formData.phoneNo} onChange={handleChange} required />
                    )
                    }
                </div>

                {isAuthRequested ? (
                  <>
                    <p className="guide-txt">카카오톡에서 인증 후 완료버튼을 눌러주세요.</p>
                    <button type="button" onClick={handleAuthResult}>
                    인증완료
                    </button>
                  </>
                ) : (
                    <button type="submit" >간편인증요청</button>
                )}
            </form>
            {loading && (
                <div className='loading-spinner'>
                    <div className='spinner'></div>
                    <p>인증을 확인중입니다.<br/>잠시만 기다려주세요</p>
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxList;