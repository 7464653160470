import React, { useState, useEffect } from 'react';
import { fetchPlayers, fetchPlayerById, deletePlayer } from '../../api';

import Layout from '../../components/layout/Layout';
import PlayerForm from '../../components/player/PlayerForm';

const App = () => {
  const [players, setPlayers] = useState([]);
  const [currentPlayerId, setCurrentPlayerId] = useState(null);

  const handleSavePlayer = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch(error => {
      console.error('There was an error fetching the players!', error);
    });
    setCurrentPlayerId(null);
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>페이지 관리</div>
        <div className='single-form-wrap'>
          <PlayerForm onSave={handleSavePlayer} />
        </div>
      </div>
    </>
  );
};

export default App;