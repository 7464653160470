import React, { useState, useEffect } from 'react';
import { fetchBoards } from '../../api';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const PlayerList = ({ players, onEdit, onDelete, setSelectedItems }) => {
  const [boardMap, setBoardMap] = useState({});
  const [shortUrls, setShortUrls] = useState({});

  useEffect(() => {
    // 업체 목록 가져오기
    fetchBoards().then(response => {
        // 업체 ID와 이름 매핑 객체 생성
        const boardMap = response.data.reduce((acc, board) => {
            acc[board.id] = board.name;
            return acc;
        }, {});
        setBoardMap(boardMap);
    }).catch(error => {
        console.error('There was an error fetching the teams!', error);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]); 
  const postsPerPage = 20;

  // players 배열을 id 기준으로 내림차순 정렬
  //const sortedPlayers = [...players].sort((a, b) => b.id - a.id);

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = players.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(players.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    let updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id) // 선택 해제
      : [...selected, id]; // 선택 추가

    setSelected(updatedSelected);
    setSelectedItems(updatedSelected); // 부모에게 선택된 항목 전달
  };


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((player, index) => (
            <li key={player.id}>
              <div className='idx'>
                <input
                  type='checkbox'
                  checked={selected.includes(player.id)}
                  onChange={() => handleCheckboxChange(player.id)}
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='page'>{player.name}</div>
              <div className='page'>{player.name_en}</div>
              <div className='page'>{boardMap[player.shop_id] || '미지정'}</div>
              <div className='page link'>
                {/* 숏링크와 QR 코드 표시 */}
                <a href={player.short_url} target='_blank' rel='noopener noreferrer'>
                  {player.short_url}
                </a>
                {player.short_url && <QRCodeCanvas value={player.short_url} style={{ width: '36px', height: '36px' }}  />}
              </div>
              <div className='controll'>
                <button onClick={() => onEdit(player.id)}>수정</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    
    </>

  );
};

export default PlayerList;