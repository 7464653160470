import React, { useState, useEffect } from 'react';
import { fetchToolSettings, deleteToolSetting } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import ToolSettingList from '../../components/toolSetting/ToolSettingList';

const App = () => {
  const [toolSettings, setToolSettings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadToolSettings();
  }, []);

  const loadToolSettings = () => {
    fetchToolSettings().then((response) => {
      setToolSettings(response.data);
    }).catch((error) => {
      console.error("Error loading toolSettings:", error);
    });
  };
  
  const handleEditToolSetting = (id) => {
    navigate(`/dashboard/service/modify/${id}`);
  };

  const handledeleteToolSetting = (id) => {
    if (window.confirm('해당 서비스를 삭제하시겠습니까?')) {
      deleteToolSetting(id).then(() => {
        loadToolSettings();
      }).catch((error) => {
        console.error("Error deleting toolSetting:", error);
      });
    }
  };

  // 선택된 항목을 삭제하는 함수
  const handleDeleteSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('서비스를 선택해주세요.');
    } else {
      if (window.confirm('선택한 서비스들을 삭제하시겠습니까?')) {
        Promise.all(selectedItems.map(id => deleteToolSetting(id))) // 모두 삭제
        .then(() => {
          loadToolSettings();
          setSelectedItems([]); // 선택된 항목 초기화
        })
        .catch(error => {
          console.error("Error deleting selected toolSettings:", error);
        });
      }
    }
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>서비스 관리</div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {toolSettings.length}</div>
          <div className='button-wrap'>
            <button onClick={handleDeleteSelectedItems} className='delete'>
                선택삭제
            </button>
            <Link to="/dashboard/service/write">서비스 등록</Link>
          </div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>선택</div>
            <div className='idx'>No</div>
            <div className='toolsettings'>서비스명</div>
            <div className='toolsettings'>월비용{'(부가세 포함)'}</div>
            <div className='controll'>관리</div>
          </div>
          <ToolSettingList toolSettings={toolSettings} onEdit={handleEditToolSetting} onDelete={handledeleteToolSetting} setSelectedItems={setSelectedItems} />
        </div>
      </div>
    </>
  );
};

export default App;