import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureCapture = ({ onChange }) => {
  const sigCanvas = useRef(null);
  const [isSaved, setIsSaved] = useState(false);

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handleSave = () => {
    const dataUrl = sigCanvas.current.toDataURL();
    onChange(dataUrl);
    setIsSaved(true);
    alert('저장되었습니다.');
  };

  return (
    <div>
      <SignatureCanvas
        ref={sigCanvas}
        penColor='black'
        canvasProps={{ width: 334, height: 120, className: 'signature-canvas' }}
      />
      {!isSaved && (
        <div className='button-wrap'>
          <button type="button" onClick={handleClear}>초기화</button>
          <button type="button" onClick={handleSave} className='submit'>저장</button>
        </div>
      )}
    </div>
  );
};

export default SignatureCapture;