import React, { useState, useEffect } from 'react';
import { fetchCms, deleteCms, fetchBoards, fetchPlayers } from '../../api';

import Layout from '../../components/layout/Layout';
import CmsList from '../../components/cms/CmsList';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const CmsComList = () => {
  const [cms, setCms] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredCms, setFilteredCms] = useState(cms);
  useEffect(() => {
    loadCms();
  }, []);

  const loadCms = () => {
    fetchCms().then((response) => {
      setCms(response.data);
      setFilteredCms(response.data);
    }).catch((error) => {
      console.error("Error loading users:", error);
    });
  };

  const handleDeleteCms = (id) => {
    if (window.confirm('해당 신청정보를 삭제하시겠습니까?')) {
      deleteCms(id).then(() => {
        loadCms();
      }).catch((error) => {
        console.error("Error deleting toolSetting:", error);
      });
    };
  };

  const handleFilter = () => {
    const newFilteredCms = cms.filter(cm => {
      const matchName = searchName === '' || cm.name.toLowerCase().includes(searchName.toLowerCase());
      const matchPhoneNo = searchName === '' || cm.phone_no.toLowerCase().includes(searchName.toLowerCase());
      
      return matchName || matchPhoneNo; // 둘 중 하나라도 일치하면 필터링된 결과에 포함
    });
    setFilteredCms(newFilteredCms);
  };

  // 선택된 항목을 삭제하는 함수
  const handleDeleteSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('신청정보를 선택해주세요.');
    } else {
      if (window.confirm('선택한 신청정보들을 삭제하시겠습니까?')) {
        Promise.all(selectedItems.map(id => deleteCms(id))) // 모두 삭제
        .then(() => {
          loadCms();
          setSelectedItems([]); // 선택된 항목 초기화
        })
        .catch(error => {
          console.error("Error deleting selected toolSettings:", error);
        });
      }
    }
  };

  useEffect(() => {
    handleFilter();
  }, [searchName, cms]);

  const exportToExcel = () => {
  
    // 데이터를 엑셀 형식으로 변환
    const ws = XLSX.utils.json_to_sheet(filteredCms.map(cm => {
      return {
        'No': cm.id,
        '상호': cm.name,
        '연락처': cm.phone_no,
        '결제방식': cm.pay_type,
        '생년월일': cm.identity,
        '예금주/결제자명': cm.deposit_name,
        '계좌번호/카드번호': cm.deposit_no,
        '신청일시': cm.confirm_date
      };
    }));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'cms신청리스트');
  
    // 엑셀 파일 다운로드
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(file, 'cms-list.xlsx');
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>CMS신청 업체 리스트</div>

        <div className='filters'>
          <div className='search'>
            <input
              type="text"
              placeholder="신청인 이름 또는 연락처"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <button onClick={handleFilter}></button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {cms.length}</div>
          <div className='export-button'>
            <button onClick={handleDeleteSelectedItems} className='delete'>
                선택삭제
            </button>
            <button onClick={exportToExcel}>엑셀 다운로드</button>
          </div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>선택</div>
            <div className='idx'>No</div>
            <div className='title'>신청인</div> 
            <div className='title'>연락처</div> 
            <div className='title'>결제방법</div> 
            <div className='title'>신청일</div>
            <div className='title'>신청서</div>
          </div>
          <CmsList 
            members={filteredCms} 
            onDelete={handleDeleteCms} 
            setSelectedItems={setSelectedItems}
          />
        </div>
      </div>
    </>
  );
};

export default CmsComList;