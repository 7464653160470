import React, { useState, useEffect } from 'react';
import { fetchWebDetail, getDownloadLink } from '../../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import AOS from "aos";
import "../../aos.css";

const CmsConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [details, setDetails] = useState([]);
  const [fileName, setFileName] = useState(location.state?.fileName || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
        const fileBlob = await getDownloadLink(fileName); // Call the API to get the blob
  
        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(new Blob([fileBlob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
          <div className='contents-wrap'>
            <div className='title-wrap'>
                <p className='title'>CMS 신청이<br/>
                완료되었습니다.</p>
            </div>
            <div className='description-wrap'>
              <p className='title'>{details.cms_c_title}</p>
              <p className='description'>{details.cms_c_desc}</p>
            </div>
            <button onClick={handleDownload} disabled={loading} className='down-link'>
                {loading ? '다운로드 중...' : '신청서 다운로드'}
            </button>

          </div>
        </div>
      </div>
    </>
  );
};

export default CmsConfirm;