import React, { useState, useEffect } from 'react';
import { createBoard, updateBoard, fetchBoardById } from '../../api';
import { useNavigate } from 'react-router-dom';

const BoardForm = ({ boardId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    name_en: '',
    business_no: '',
    cert_file: '',
    key_file: '',
    cert_password: '',
    manage_no: '',
    manage_password: '',
  });

  const [existingCertFile, setExistingCertFile] = useState(null);
  const [existingKeyFile, setExistingKeyFile] = useState(null);
  const [newCertFileName, setNewCertFileName] = useState(null);
  const [newKeyFileName, setNewKeyFileName] = useState(null);

  useEffect(() => {
    if (boardId) {
      fetchBoardById(boardId).then((response) => {
        var chkId = parseInt(boardId);
        const board = response.data.find((s) => s.id === chkId);
        setFormData({
          name: board.name || '',
          name_en: board.name_en || '',
          business_no: board.business_no || '',
          cert_file: '', // 새로 선택한 파일이 없으면 빈 상태로 유지
          key_file: '',
          cert_password: board.cert_password || '',
          manage_no: board.manage_no || '',
          manage_password: board.manage_password || ''
        });
        setExistingCertFile(board.cert_file || null);
        setExistingKeyFile(board.key_file || null);
      });
    }
  }, [boardId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, [e.target.name]: file });
    
    // 새로운 파일명 상태로 설정
    if (e.target.name === 'cert_file') {
      setExistingCertFile(null);  // 기존 파일 경로 초기화
      setNewCertFileName(file.name);
    }
    if (e.target.name === 'key_file') {
      setExistingKeyFile(null);  // 기존 파일 경로 초기화
      setNewKeyFileName(file.name);
    }
  };

  const handleFileButtonClick = (fileType) => {
    document.getElementById(fileType).click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    for (let key in formData) {
      if (key === 'cert_file' && !formData[key]) {
        formDataToSend.append(key, existingCertFile);
      } else if (key === 'key_file' && !formData[key]) {
        formDataToSend.append(key, existingKeyFile);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    if (boardId) {
      await updateBoard(boardId, formDataToSend);
    } else {
      await createBoard(formDataToSend);
    }
    navigate('/dashboard/shop/list');
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };


  return (
    <form onSubmit={handleSubmit}>
      <div className="title-wrap">
        <p className="title">
          {boardId ? '지점 수정' : '새 지점'}
        </p>
        <button type="button" onClick={handleGoBack} className="clean">
          돌아가기
        </button>
      </div>
      <div className="input-wrap">
        <p className="label">지점명</p>
        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="지점명" />
      </div>
      <div className="input-wrap">
        <p className="label">영문관리명{'('}url{')'}</p>
        <input type="text" name="name_en" value={formData.name_en} onChange={handleChange} placeholder="url에 사용될 영문관리명  ex)'도메인.com/영문관리명'" />
      </div>
      <div className="input-wrap">
        <p className="label">사업자등록번호</p>
        <input type="text" name="business_no" value={formData.business_no} onChange={handleChange} placeholder="'-' 하이픈을 추가한 번호를 입력해주세요." />
      </div>

      {/* 인증서 관련 필드 추가 */}
      <div className="input-wrap">
        <p className="label">인증서 파일</p>
        {(existingCertFile || newCertFileName) && (
          <p>현재 파일: {newCertFileName || existingCertFile}</p>
        )}
        <button type="button" onClick={() => handleFileButtonClick('cert_file')}>
          {newCertFileName || existingCertFile ? '수정' : '추가'}
        </button>
        <input 
          type="file" 
          id="cert_file" 
          name="cert_file" 
          onChange={handleFileChange} 
          style={{ display: 'none' }} 
        />
      </div>
      <div className="input-wrap">
        <p className="label">개인 키 파일</p>
        {(existingKeyFile || newKeyFileName) && (
          <p>현재 파일: {newKeyFileName || existingKeyFile}</p>
        )}
        <button type="button" onClick={() => handleFileButtonClick('key_file')}>
          {newKeyFileName || existingKeyFile ? '수정' : '추가'}
        </button>
        <input 
          type="file" 
          id="key_file" 
          name="key_file" 
          onChange={handleFileChange} 
          style={{ display: 'none' }} 
        />
      </div>
      <div className="input-wrap">
        <p className="label">인증서 비밀번호</p>
        <input type="password" name="cert_password" value={formData.cert_password} onChange={handleChange} placeholder="인증서 비밀번호 입력" />
      </div>
      <div className="input-wrap">
        <p className="label">세무대리인 관리번호</p>
        <input type="text" name="manage_no" value={formData.manage_no} onChange={handleChange} placeholder="세무대리인 관리번호" />
      </div>
      <div className="input-wrap">
        <p className="label">세무대리인 관리 비밀번호</p>
        <input type="password" name="manage_password" value={formData.manage_password} onChange={handleChange} placeholder="세무대리인 관리 비밀번호" />
      </div>
      <button type="submit">저장</button>
    </form>
  );
};

export default BoardForm;