import React, { useState, useEffect } from 'react';
import { fetchBoards } from '../../api';

import Layout from '../../components/layout/Layout';
import BoardForm from '../../components/board/BoardForm';

const App = () => {
  const [boards, setBoards] = useState([]);
  const [currentBoardId, setCurrentBoardId] = useState(null);

  const handleSaveBoard = () => {
    fetchBoards().then((response) => {
      setBoards(response.data);
    }).catch(error => {
      console.error('There was an error fetching the boards!', error);
    });
    setCurrentBoardId(null);
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>지점 관리</div>
        <div className='single-form-wrap'>
          <BoardForm onSave={handleSaveBoard} />
        </div>
      </div>
    </>
  );
};

export default App;