import React, { useState, useEffect } from 'react';
import { getDownloadLink } from '../../api';
const CmsList = ({ members, onDelete, setSelectedItems }) => {

  const formatDate = (dateString) => {
    const currentDate = new Date(dateString);

    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]); 
  const postsPerPage = 20;

  // 신청일 기준으로 내림차순 정렬
  const sortedMembers = [...members].sort((a, b) => new Date(b.confirm_date) - new Date(a.confirm_date));

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = sortedMembers.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedMembers.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // 파일 다운로드 핸들러
  const handleDownload = async (fileName) => {
    try {
      // 서버에서 파일 Blob 데이터를 받아오는 API 호출
      const fileBlob = await getDownloadLink(fileName); // API를 통해 파일 Blob 받기
  
      // Blob 데이터를 사용해 다운로드 링크 생성
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('파일 다운로드 중 오류 발생:', error);
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    let updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id) // 선택 해제
      : [...selected, id]; // 선택 추가

    setSelected(updatedSelected);
    setSelectedItems(updatedSelected); // 부모에게 선택된 항목 전달
  };


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((member, index) => (
            <li key={member.id}>
              <div className='idx'>
                <input
                  type='checkbox'
                  checked={selected.includes(member.id)}
                  onChange={() => handleCheckboxChange(member.id)}
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='title'>{member.name}</div> 
              <div className='title'>{member.phone_no}</div> 
              <div className='title'>{member.pay_type}</div> 
              <div className='title'>{formatDate(member.confirm_date)}</div>
              <div className='title'>
                <button onClick={() => handleDownload(member.down_link)}>
                  다운로드
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default CmsList;