import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchBoards, fetchPlayers, fetchWebDetail } from '../api.js'
import LayoutClient from '../components/layout/LayoutClient';
import AOS from "aos";
import "../aos.css";

const Main = () => {
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    AOS.init();
  },[]);

  useEffect(() => {
    loadPlayers();
    loadBoards();
    loadDetails();
  }, []);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const generateLinks = () => {
    return players.map((player) => {
      const board = boards.find(board => board.id === parseInt(player.shop_id));
      if (board) {
        if ( player.main_use === '사용함') {
          return (
            <Link 
              key={player.id} 
              to={`/apply/${board.name_en}/${player.name_en}`}
            >
              {player.name}
            </Link>
          );
        }
      }
      return null;
    });
  };

  return (
    <>
      <LayoutClient />
        <div className='client-contents'>
          <div className='inner'>
            <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
            <div className='contents-wrap'>
              <div className='title-wrap'>
                <p className='title'>{details.main_title}</p>
                <div className='desc'>
                  {details.main_desc}
                </div>
              </div>
              <div className='image-wrap mo'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
              <div className='link-wrap pc' data-aos="fade-in" data-aos-duration="600" data-aos-easing="ease-in-sine">
                {generateLinks()}
              </div>
              <div className='link-wrap mo'>
                {generateLinks()}
              </div>
            </div>
          </div>
        </div>
    </>

  );
};

export default Main;