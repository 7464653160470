import React, { useState, useEffect } from 'react';
import { fetchPlayers, deletePlayer, fetchBoards } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import PlayerList from '../../components/player/playerList';

const App = () => {
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPlayers();
    loadBoards();
  }, []);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
      setFilteredPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const handleFilter = () => {
    const newFilteredPlayers = players.filter(player => {
      const matchBoard = selectedBoards === '' || player.shop_id === selectedBoards;
      return matchBoard;
    });
    setFilteredPlayers(newFilteredPlayers);
  };

  const handleEditPlayer = (id) => {
    navigate(`/dashboard/page/modify/${id}`);
  };

  const handleDeletePlayer = (id) => {
    if (window.confirm('해당 페이지를 삭제하시겠습니까?')) {
      deletePlayer(id).then(() => {
        loadPlayers();
      }).catch((error) => {
        console.error("Error deleting toolSetting:", error);
      });
    };
  };

  // 선택된 항목을 삭제하는 함수
  const handleDeleteSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('페이지를 선택해주세요.');
    } else {
      if (window.confirm('선택한 페이지들을 삭제하시겠습니까?')) {
        Promise.all(selectedItems.map(id => deletePlayer(id))) // 모두 삭제
        .then(() => {
          loadPlayers();
          setSelectedItems([]); // 선택된 항목 초기화
        })
        .catch(error => {
          console.error("Error deleting selected toolSettings:", error);
        });
      }
    }
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>페이지 관리</div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedBoards} onChange={(e) => setSelectedBoards(e.target.value)}>
              <option value=''>지점 선택</option>
              {boards.map(board => (
                <option key={board.id} value={board.id}>{board.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {filteredPlayers.length}</div>
          <div className='button-wrap'>
            <button onClick={handleDeleteSelectedItems} className='delete'>
                선택삭제
            </button>
            <Link to="/dashboard/page/write">페이지 등록</Link>
          </div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>선택</div>
            <div className='idx'>No</div>
            <div className='page'>페이지명</div> 
            <div className='page'>영문관리명</div> 
            <div className='page'>관리허용지점</div>
            <div className='page link'>생성링크</div>
            <div className='controll'>관리</div>
          </div>
          <PlayerList players={filteredPlayers} onEdit={handleEditPlayer} onDelete={handleDeletePlayer} setSelectedItems={setSelectedItems} />
        </div>
      </div>
    </>
  );
};

export default App;