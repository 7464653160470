import React, { useState, useEffect } from 'react';
import { fetchWebDetail, updateWebDetail } from '../../api';
import { useNavigate } from 'react-router-dom';

const WebForm = ({ toolSettingId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    main_title: '',
    main_desc: '',
    apply_title: '',
    apply_desc: '',
    agree_title: '',
    agree_desc: '',
    confirm_title: '',
    confirm_desc: '',
    cms_title:'',
    cms_desc:'',
    cms_c_title:'',
    cms_c_desc:'',
    info_provide_desc:''
  });

  useEffect(() => {
    fetchWebDetail().then((response) => {
        const data = response.data[0];
        console.log(data);
        setFormData({
            main_title: data.main_title || '',
            main_desc: data.main_desc || '',
            apply_title: data.apply_title || '',
            apply_desc: data.apply_desc || '',
            agree_title: data.agree_title || '',
            agree_desc: data.agree_desc || '',
            confirm_title: data.confirm_title || '',
            confirm_desc: data.confirm_desc || '',
            cms_title: data.cms_title || '',
            cms_desc: data.cms_desc || '',
            cms_c_title: data.cms_c_title || '',
            cms_c_desc: data.cms_c_desc || '',
            info_provide_desc: data.info_provide_desc || ''
        });
    }).catch(error => {
    console.error('Error fetching:', error);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateWebDetail(formData).then((onSave) => {
        alert('정보가 저장되었습니다.');
    });
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };


  return (
    <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
            <p className="title">웹사이트 페이지 설명 수정</p><div className='button-wrap'><button type="submit">저장</button></div>
        </div>

        <div className="input-wrap align-top">
          <p className="label">메인 페이지</p>
          <div className="desc-wrapper">
            <input type="text" name="main_title" value={formData.main_title} onChange={handleChange} placeholder="메인페이지 타이틀" />
            <textarea name="main_desc" value={formData.main_desc} onChange={handleChange} placeholder="메인페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">신청서 인트로 페이지</p>
          <div className="desc-wrapper">
            <input type="text" name="apply_title" value={formData.apply_title} onChange={handleChange} placeholder="인트로 페이지 타이틀" />
            <textarea name="apply_desc" value={formData.apply_desc} onChange={handleChange} placeholder="인트로 페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">수임동의 안내 페이지</p>
          <div className="desc-wrapper">
            <input type="text" name="agree_title" value={formData.agree_title} onChange={handleChange} placeholder="수임동의 안내 페이지 타이틀" />
            <textarea name="agree_desc" value={formData.agree_desc} onChange={handleChange} placeholder="수임동의 안내 페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">신청 완료페이지</p>
          <div className="desc-wrapper">
          <input type="text" name="confirm_title" value={formData.confirm_title} onChange={handleChange} placeholder="신청완료 페이지 타이틀" />
          <textarea name="confirm_desc" value={formData.confirm_desc} onChange={handleChange} placeholder="신청완료 페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">{'('}CMS{')'} 신청페이지</p>
          <div className="desc-wrapper">
          <input type="text" name="cms_title" value={formData.cms_title} onChange={handleChange} placeholder="CMS 신청 페이지 타이틀" />
          <textarea name="cms_desc" value={formData.cms_desc} onChange={handleChange} placeholder="CMS 신청 페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">{'('}CMS{')'} 신청 완료 페이지</p>
          <div className="desc-wrapper">
          <input type="text" name="cms_c_title" value={formData.cms_c_title} onChange={handleChange} placeholder="CMS 신청 완료 페이지 타이틀" />
          <textarea name="cms_c_desc" value={formData.cms_c_desc} onChange={handleChange} placeholder="CMS 신청 완료 페이지 설명"></textarea>
          </div>
        </div>
        <div className="input-wrap align-top">
          <p className="label">{'('}수임납세자 등록페이지{')'} 정보제공범위 설명</p>
          <div className="desc-wrapper">
          <textarea name="info_provide_desc" value={formData.info_provide_desc} onChange={handleChange} placeholder="최초 세무대리인 수임납세자 등록 시, 타소득포함/해당사업장의 고객 이해를 돕기 위한 설명입니다."></textarea>
          </div>
        </div>
        <button type="submit">저장</button>
    </form>
  );
};

export default WebForm;