import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers, fetchWebDetail, fetchToolSettings, updateUserService } from '../../api';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import AOS from "aos";
import "../../aos.css";

const ApplyConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state;
  const { shopname, webname } = useParams();
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [details, setDetails] = useState([]);
  const [toolSettings, setToolSettings] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]); 

  useEffect(() => {
    loadPlayers();
    loadBoards();
    loadDetails();
    loadToolSettings();
  }, []);

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadToolSettings = () => {
    fetchToolSettings().then(response => {
      setToolSettings(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);

    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  };

  useEffect(() => {
    if (currentPlayer && toolSettings.length > 0) {
      const serviceArr = JSON.parse(currentPlayer.use_services); // use_services가 JSON 문자열이라면
      const filteredServices = toolSettings.filter(tool => serviceArr.includes(tool.id)); // id로 필터링
      setSelectedServices(filteredServices);
    }
  }, [currentPlayer, toolSettings]);

  // 체크박스 변경 시 호출되는 함수
  const handleCheckboxChange = (serviceId) => {
    setCheckedServices(prevChecked => {
      if (prevChecked.includes(serviceId)) {
        return prevChecked.filter(id => id !== serviceId); // 이미 체크된 항목은 제거
      } else {
        return [...prevChecked, serviceId]; // 체크되지 않은 항목은 추가
      }
    });
  };

  const handleApply = () => {
    const appliedServices = selectedServices.filter(service => checkedServices.includes(service.id));
    console.log("신청한 서비스들:", appliedServices);
    updateUserService(userId, appliedServices).then(response => {
      alert('신청이 완료되었습니다.');
      navigate(`/apply/${shopname}/${webname}`);
    });
  };
  

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
          <div className='contents-wrap'>
            <div className='title-wrap'>
                <p className='title'>세무대리인 수임동의가<br/>
                완료되었습니다.</p>
            </div>
            <div className='description-wrap'>
              <p className='title'>{details.confirm_title}</p>
              <p className='description'>{details.confirm_desc}</p>
            </div>
            {selectedServices.length > 0 ? (
            <div className='services-list'>
              <h3>혹시 추가적인 서비스가 필요하신가요?</h3>
              <ul>
                {selectedServices.map(service => (
                  <li key={service.id}>
                    <label>
                      <input 
                        type="checkbox" 
                        checked={checkedServices.includes(service.id)} 
                        onChange={() => handleCheckboxChange(service.id)} 
                      />
                      <span className='name'>{service.name}</span>
                      <span>{'('}{service.price.toLocaleString()}원{')'}</span>
                    </label>
                  </li>
                ))}
              </ul>
              <button onClick={handleApply} className="apply-button">신청하기</button>
            </div>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyConfirm;