import React, { useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { fetchBoards, fetchPlayers } from '../../api';
import LayoutClient from '../../components/layout/LayoutClient';

const BeforeAgree = () => {
  const location = useLocation();
  const { resultData } = location.state || {};
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const { shopname, webname } = useParams();
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);


  useEffect(() => {
    loadPlayers();
    loadBoards();
  }, []);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);
  
    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  };

  console.log(resultData);


  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap'>
            <div className='login-form'>
              <p className='sub-title'>신청인이 보유한 사업자 내 아직 수임동의를 진행하지 않으신것이 있네요!<br/>만약 '타소득포함'으로 수임동의하신 이력이 있으시다면 다른 사업장은 수임동의 진행을 하지 않으셔도 됩니다.</p>
              {resultData ? (
                    <div className='result-details'>
                          <div className='input-wrap single'>
                            <div className='inputs'>
                              <p className='label'>사업자번호{'('}개인의 경우 주민등록번호{')'}</p> {resultData.resTaxpayerNo}
                            </div>
                            <div className='inputs'>
                              <p className='label'>세무대리인</p> {resultData[0].resCompanyNm}
                            </div>
                            <div className='inputs'>
                              <p className='label'>세무대리인 사업자번호</p> {resultData[0].resCompanyIdentityNo}
                            </div>
                            <div className='inputs'>
                              <p className='label'>최초신청일자</p> {resultData[0].resCommissionDate}
                            </div>
                            <div className='inputs'>
                              <p className='label'>정보제공범위</p> {resultData[0].resInfoProvideRange}
                            </div>
                            <div className='inputs'>
                              <p className='label'>수임동의 여부</p> {resultData[0].resInfoProvideAgreeYN}
                            </div>
                          </div>
                    </div>
                ) : (
                    <p>인증 데이터가 없습니다.</p>
                )}
                <div className='link-wrap'>
                    <Link to={`/apply/${shopname}/${webname}/verify`}>수임동의 진행</Link>
                </div>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default BeforeAgree;