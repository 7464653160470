import React, { useState, useEffect } from 'react';
import { createToolSetting, updateToolSetting, fetchToolSettingById } from '../../api';
import { useNavigate } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToolSettingForm = ({ toolSettingId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    price: ''
  });

  useEffect(() => {
    if (toolSettingId) {
      toolSettingId = parseInt(toolSettingId);
      fetchToolSettingById(toolSettingId).then((response) => {
        const toolSetting = response.data.find((s) => s.id === toolSettingId);
        setFormData({
          name: toolSetting.name || '',
          price: formatNumber(toolSetting.price) || '',
        });
      });
    }
  }, [toolSettingId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // 가격 필드에 입력할 때 쉼표 추가 로직 적용
    if (name === 'price') {
      const rawValue = value.replace(/,/g, '');  // 쉼표 제거
      if (!isNaN(rawValue)) {
        setFormData({ ...formData, [name]: formatNumber(rawValue) });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // 숫자를 1000자리마다 쉼표로 포맷하는 함수
  const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedData = {
      ...formData,
      price: parseFloat(formData.price.replace(/,/g, '')) // 저장할 때 쉼표 제거
    };

    if (toolSettingId) {
      updateToolSetting(toolSettingId, formattedData).then((onSave) => {
        navigate('/dashboard/service/list');
      });
    } else {
      createToolSetting(formattedData).then((onSave) => {
        navigate('/dashboard/service/list');
      });
    }
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };
  

  return (
    <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
        {toolSettingId ? (
            <><p className="title">{formData.name}</p><div className='button-wrap'></div></>
          ) : (
            <><p className="title">새 서비스</p><div className='button-wrap'></div></>
          )}
          <button type="button" onClick={handleGoBack} className="clean">
          돌아가기
        </button>
        </div>

        <div className="input-wrap">
          <p className="label">서비스명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="서비스명" />
        </div>

        <div className="input-wrap">
          <p className="label">월비용 {'(부가세 포함)'}</p>
          <input type="text" name="price" value={formData.price} onChange={handleChange} placeholder="월 비용" />
        </div>

        <button type="submit">저장</button>
    </form>
  );
};

export default ToolSettingForm;