import axios from 'axios';

//const API_URL = 'http://localhost:3001/api';
const API_URL = 'https://taxson.co.kr/api';

export const sendThirdPartyData = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/send-auth-request`, formData);
    return response.data; // 간편인증 요청에 대한 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const sendTaxListAuth = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/send-tax-auth-request`, formData);
    return response.data; // 간편인증 요청에 대한 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const sendTaxAuthResult = async (authData) => {
  try {
    const response = await axios.post(`${API_URL}/send-tax-auth-result`, authData);
    return response.data; // 간편인증 완료 후의 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const sendDismissalAuth = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/send-dismissal-request`, formData);
    return response.data; // 간편인증 요청에 대한 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const sendDismissalResult = async (authData) => {
  try {
    const response = await axios.post(`${API_URL}/send-dismissal-result`, authData);
    return response.data; // 간편인증 완료 후의 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const sendAuthResult = async (authData) => {
  try {
    const response = await axios.post(`${API_URL}/send-auth-result`, authData);
    return response.data; // 간편인증 완료 후의 응답 반환
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const applyTax = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/applyTax`, data);
    return response.data;
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export const applyCms = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/cms`, data);
    return response;
  } catch (error) {
    console.error('Error applying CMS:', error);
    throw error;
  }
};

export const createCms = (data) => {
  return axios.post(`${API_URL}/cms/create`, data);
};

export const fetchCms = () => {
  return axios.get(`${API_URL}/cms/cmsList`);
};

export const deleteCms = (id, data) => {
  return axios.delete(`${API_URL}/cms/${id}`);
};


export const getDownloadLink = async (fileName) => {
  try {
    const response = await axios.get(`${API_URL}/cms/download`, {
      params: { fileName }, // Send the file name as a query parameter
      responseType: 'blob', // File data as blob
    });
    return response.data; // 다운로드 링크 반환
  } catch (error) {
    console.error('Error fetching download link:', error);
    throw error;
  }
};

// 관리자 API 추가
export const adminLogin = (data) => {
  return axios.post(`${API_URL}/admin/login`, data);
};

export const verifyAdminToken = (token) => {
  return axios.post(`${API_URL}/admin/verify`, {}, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const login = (data) => {
  return axios.post(`${API_URL}/users/login`, data);
};

export const signup = (data) => {
  return axios.post(`${API_URL}/users/signup`, data);
};

export const fetchProfile = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const updateUserService = (userId, service) => {
  return axios.put(
    `${API_URL}/users/${userId}/username`, 
    { use_service: service }
  );
};

export const updateProfile = (data) => {
  return axios.put(`${API_URL}/users/profile`, data);
};

export const checkEmailDuplication = (email) => {
  return axios.post(`${API_URL}/users/check-email`, { email });
};

export const checkUsernameDuplication = (username) => {
  return axios.post(`${API_URL}/users/check-username`, { username });
};

export const sendVerificationCode = (email) => {
  return axios.post(`${API_URL}/users/send-verification-code`, { email });
};

export const verifyCode = (email, verificationCode) => {
  return axios.post(`${API_URL}/users/verify-code`, { email, verificationCode });
};


// Member APIs
export const fetchUsers = () => {
  return axios.get(`${API_URL}/users/memberList`);
};

export const deleteUser = (id, data) => {
  return axios.delete(`${API_URL}/users/${id}`);
};

export const changeUserGrade = (userIds, newGradeId) => {
  return axios.post(`${API_URL}/users/change-grade`, { userIds, newGradeId });
};

export const getProfile = () => {
  return axios.get(`${API_URL}/users`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const fetchUserData = (id) => {
  return axios.get(`${API_URL}/users/${id}`);
};

export const fetchUserPosts = async (userId, token) => {
  const response = await fetch(`${API_URL}/posts/user/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.json();
};

//Web APIs
export const fetchWebDetail = () => {
  return axios.get(`${API_URL}/webs`);
};

export const updateWebDetail = (data) => {
  return axios.put(`${API_URL}/webs`, data);
};

//Player APIs
export const fetchPlayers = () => {
  return axios.get(`${API_URL}/players`);
};

export const fetchPlayerById = (id) => {
  return axios.get(`${API_URL}/players/${id}`);
};

export const createPlayer = (data) => {
  return axios.post(`${API_URL}/players`, data);
};

export const updatePlayer = (id, data) => {
  return axios.put(`${API_URL}/players/${id}`, data);
};

export const deletePlayer = (id, data) => {
  return axios.delete(`${API_URL}/players/${id}`);
};


//Tool Setting APIs
export const fetchToolSettings = () => {
  return axios.get(`${API_URL}/toolSettings`);
};

export const fetchToolSettingById = (id) => {
  return axios.get(`${API_URL}/toolSettings/${id}`);
};

export const createToolSetting = (data) => {
  return axios.post(`${API_URL}/toolSettings`, data);
};

export const updateToolSetting = (id, data) => {
  return axios.put(`${API_URL}/toolSettings/${id}`, data);
};

export const deleteToolSetting = (id, data) => {
  return axios.delete(`${API_URL}/toolSettings/${id}`);
};

// Board APIs
export const fetchBoards = () => {
  return axios.get(`${API_URL}/boards`);
};

export const fetchBoardById = (id) => {
  return axios.get(`${API_URL}/boards/${id}`);
};

export const createBoard = (data) => {
  return axios.post(`${API_URL}/boards`, data);
};

export const updateBoard = (id, data) => {
  return axios.put(`${API_URL}/boards/${id}`, data);
};

export const deleteBoard = (id) => {
  return axios.delete(`${API_URL}/boards/${id}`);
};

// Board - Post APIs
export const fetchPosts = () => {
  return axios.get(`${API_URL}/posts/`);
};

export const fetchPostsCategory = (id) => {
  return axios.get(`${API_URL}/posts/category/${id}`);
};

export const fetchPostById = (id) => {
  return axios.get(`${API_URL}/posts/${id}`);
};

// HTML 콘텐츠에서 이미지 URL을 추출하는 함수
export const extractImageUrlsFromContent = (content) => {
  const imageUrls = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  
  doc.querySelectorAll('img').forEach(img => {
    if (img.src) {
      imageUrls.push(img.src);
    }
  });

  return imageUrls;
}; 

export const createPost = (postData, userId, token) => {
  const formData = new FormData();
  formData.append('title', postData.title);
  formData.append('content', postData.content);
  formData.append('category_id', postData.category_id);
  formData.append('user_id', userId);

  // Quill 에디터에서 이미지 URL을 추출하고 추가
  const imageUrls = extractImageUrlsFromContent(postData.content); // 이 함수는 이미지 URL을 추출하는 데 필요합니다.
  imageUrls.forEach((url, index) => formData.append(`image_${index}`, url));

  return axios.post(`${API_URL}/posts`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updatePost = (id, data) => {
  return axios.put(`${API_URL}/posts/${id}`, data);
};

export const deletePost = (id) => {
  return axios.delete(`${API_URL}/posts/${id}`);
};
