// components/layout/Layout.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './layout.css';

const Layout = () => {
    return (
        <>
            <div className='gnb'>
                <a href="/" target="_blank">웹 바로가기</a>
            </div>

            <div className='snb'>
                <Link to="/dashboard/shop/list" className='logo-wrap'>ADMIN</Link>
                <Link to="/dashboard/shop/list">지점 관리</Link>
                <Link to="/dashboard/page/list">페이지 관리</Link>
                <Link to="/dashboard/service/list">서비스 관리</Link>
                <Link to="/dashboard/web/modify">웹페이지 설명 관리</Link>
                <Link to="/dashboard/member/list">수임동의 업체 리스트</Link>
                <Link to="/dashboard/cms/list">CMS신청 업체 리스트</Link>
            </div>
        </>
    )
}

export default Layout;
