import React, { useState, useEffect } from 'react';
import { fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';

const ApplyCms = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };


  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap'>
            <div>
              <div className='title-wrap'>
                <p className='title'><br/>CMS 자동이체 신청</p>
              </div>
              <div className='description-wrap'>
                <p className='title'>{details.cms_title}</p>
                <p className='description'>{details.cms_desc}</p>
              </div>
            </div>
            <div className='link-wrap'>
              <Link to={`/cms/apply`}>CMS 자동이체 신청하기</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyCms;