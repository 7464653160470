import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPlayers, fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import AOS from "aos";
import "../../aos.css";
import Modal from './ConsentModal.js';

const Check = () => {
  const navigate = useNavigate();
  const { shopname, webname } = useParams();
  const [players, setPlayers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [details, setDetails] = useState([]);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);

  useEffect(() => {
    AOS.init();
  },[]);

  useEffect(() => {
    loadPlayers();
    loadBoards();
    loadDetails();
  }, []);

  useEffect(() => {
    if (players.length > 0 && boards.length > 0) {
      filterData();
    }
  }, [players, boards, shopname, webname]);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const filterData = () => {
    const board = boards.find(b => b.name_en === shopname);
    const player = players.find(p => p.name_en === webname);

    setCurrentBoard(board || null);
    setCurrentPlayer(player || null);
  };

  const agreeChk = () => {
    if (privacyAccepted) {
      navigate(`/apply/${shopname}/${webname}/verifyTax`);
    } else {
      alert('개인정보 처리방침에 동의해주세요.');
    }
  }

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-suim.svg`} /></div>
          <div className='contents-wrap'>
              {currentBoard && currentPlayer && (
              <>
                <div>
                  <p className='sub-title'>수임동의가 필요합니다</p>
                </div>
                <div className='image-wrap mo'><img src={`${process.env.PUBLIC_URL}/img/img-suim.svg`} /></div>

                <div className='description-wrap'>
                  <p className='title'>{details.agree_title}</p>
                  <p className='description'>{details.agree_desc}</p>
                </div>

                <div className='link-wrap pc' data-aos="fade-in" data-aos-duration="600" data-aos-easing="ease-in-sine">
                  <div className='consent-section'>
                    <div className='agree'>
                      <label>
                        <input
                          type="checkbox"
                          checked={privacyAccepted}
                          onChange={() => setPrivacyAccepted(!privacyAccepted)}
                        />
                        개인정보 처리방침에 동의합니다.
                      </label>
                      <button type="button" onClick={() => setShowPrivacyModal(true)}>자세히</button>
                    </div>
                  </div>
                  <a onClick={agreeChk}>수임동의 신청</a>
                </div>
                <div className='link-wrap mo'>
                  <div className='consent-section'>
                    <div className='agree'>
                      <label>
                        <input
                          type="checkbox"
                          checked={privacyAccepted}
                          onChange={() => setPrivacyAccepted(!privacyAccepted)}
                        />
                        개인정보 처리방침에 동의합니다.
                      </label>
                      <button type="button" onClick={() => setShowPrivacyModal(true)}>자세히</button>
                    </div>
                  </div>
                  <a onClick={agreeChk}>수임동의 신청</a>
                </div>
              </>
              )}
          </div>
        </div>
      </div>
      <Modal isOpen={showPrivacyModal} onClose={() => setShowPrivacyModal(false)} title="개인정보 처리방침">
        {currentPlayer && (
          <>
            {currentPlayer.agreement}
          </>
        )}
      </Modal>
    </>
  );
};

export default Check;