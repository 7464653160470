import React, { useState, useEffect, useRef } from 'react';
import { createPlayer, updatePlayer, fetchPlayerById, fetchBoards, fetchToolSettings } from '../../api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const PlayerForm = ({ playerId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [boards, setBoards] = useState([]);
  const [toolSettings, setToolSettings] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    name_en: '',
    shop_id: '',
    agreement: '',
    use_services: [],
    main_use: '사용안함',
    short_url: '',
  });

  // 기존 값을 저장할 state (초기 로딩 후 변경 감지)
  const [originalData, setOriginalData] = useState({
    name_en: '',
    shop_id: '',
  });

  useEffect(() => {

    if (playerId) {
      playerId = parseInt(playerId);
      fetchPlayerById(playerId).then((response) => {
        const data = response.data;
        
        if (data && typeof data === 'object' && data.id === playerId) {
          // data가 단일 객체일 때
          const player = data;

          setFormData({
            name: player.name || '',
            name_en: player.name_en || '',
            shop_id: player.shop_id || '',
            agreement: player.agreement || '',
            use_services: player.use_services || [],
            main_use: player.main_use || '사용안함',
            short_url: player.short_url || '',
          });

          // 기존 값을 저장
          setOriginalData({
            name_en: player.name_en || '',
            shop_id: player.shop_id || '',
          });

        } else {
          console.error('Unexpected data format:', data);
        }
      }).catch(error => {
        console.error('Error fetching player by ID:', error);
      });
    }
  }, [playerId]);

  useEffect(() => {
    fetchBoards().then(response => {
      setBoards(response.data);
    }).catch(error => {
      console.error('There was an error fetching the teams!', error);
    });
  }, []);

  useEffect(() => {
    fetchToolSettings().then(response => {
      setToolSettings(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tools!', error);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const serviceId = parseInt(value);
  
    if (checked) {
      // 체크되었을 때 서비스 추가
      setFormData(prevFormData => ({
        ...prevFormData,
        use_services: [...prevFormData.use_services, serviceId],
      }));
    } else {
      // 체크 해제되었을 때 서비스 제거
      setFormData(prevFormData => ({
        ...prevFormData,
        use_services: prevFormData.use_services.filter(id => id !== serviceId),
      }));
    }
  };

  // 이전 페이지로 돌아가는 함수
  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const createShortLink = async (longUrl) => {
      try {
        const response = await axios.post(
          'https://api-ssl.bitly.com/v4/shorten',
          { long_url: longUrl },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BITLY_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data.link);
        return response.data.link;
      } catch (error) {
        console.error('Error creating short link:', error);
        return ''; // 오류 시 빈 값 반환
      }
    };

    // name_en 또는 shop_id가 변경된 경우에만 숏링크를 업데이트
    let shortUrl = formData.short_url; // 기존 숏링크 유지

    if (formData.name_en !== originalData.name_en || formData.shop_id !== originalData.shop_id) {
      const longUrl = `https://taxson.co.kr/apply/${boards.find(board => board.id === parseInt(formData.shop_id))?.name_en || 'unknown'}/${formData.name_en}`;
      shortUrl = await createShortLink(longUrl);  // 변경 시 숏링크 업데이트
    }

    // 최종 데이터 준비
    const finalFormData = {
      ...formData,
      short_url: shortUrl,  // 업데이트된 숏링크 저장
    };

    if (playerId) {
      updatePlayer(playerId, finalFormData).then(() => {
        onSave();
        navigate('/dashboard/page/list');
      });
    } else {
      createPlayer(finalFormData).then(() => {
        onSave();
        navigate('/dashboard/page/list');
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
          {playerId ? (
            <><p className="title">{formData.name}</p></>
          ) : (
            <><p className="title">새 페이지</p></>
          )}
          <button type="button" onClick={handleGoBack} className="clean">
          돌아가기
        </button>
        </div>
        <div className="sub-title">기본 설정</div>
        
        <div className="input-wrap">
          <p className="label">페이지명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="랜딩페이지 첫 화면 타이틀에 들어가는 페이지명 ex) 'OOOO 세무기장 신청서'" />
        </div>

        <div className='input-wrap'>
          <p className='label'>메인페이지 링크 노출</p>
          <div className='radio-wrap'>
            <label>
              <input type="radio" name="main_use" value="사용함" checked={formData.main_use === '사용함'} onChange={handleChange} /> 노출함
            </label>
            <label>
              <input type="radio" name="main_use" value="사용안함" checked={formData.main_use === '사용안함'} onChange={handleChange} /> 노출안함
            </label>
          </div>
        </div>

        <div className="input-wrap">
          <p className="label">영문관리명</p>
          <input type="text" name="name_en" value={formData.name_en} onChange={handleChange} placeholder="url에 사용되는 영문관리명  ex) 도메인.com/지점영문명/OOO" />
        </div>

        <div className="input-wrap">
            <p className="label">관리 지점</p>
            <select name="shop_id" value={formData.shop_id} onChange={handleChange}>
                <option value="">관리 지점을 선택해주세요</option>
                {boards.map((board) => (
                <option key={board.id} value={board.id}>
                    {board.name}
                </option>
                ))}
            </select>
        </div>

        <div className="input-wrap">
          <p className="label">개인정보 처리방침 약관</p>
          <textarea name="agreement" value={formData.agreement} onChange={handleChange} placeholder="해당 페이지에 노출될 이용약관" rows="10" />
        </div>

        <div className="input-wrap">
          <p className="label">노출 서비스</p>
          <div className='chk-wrap'>
            {toolSettings.map(tool => (
              <div key={tool.id}>
                <label>
                  <input 
                    type="checkbox" 
                    value={tool.id}
                    checked={formData.use_services.includes(tool.id)}
                    onChange={handleCheckboxChange}
                  />
                  {tool.name} {'('}{tool.price}원{')'}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className='bottom-button-wrap'>
          <button type="submit">저장</button>
        </div>
      </form>
    </>
  );
};

export default PlayerForm;
