import React, { useState, useEffect } from 'react';
import { fetchBoards, deleteBoard } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import BoardList from '../../components/board/BoardList';

const App = () => {
  const [boards, setBoards] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadBoards();
  }, []);

  const loadBoards = () => {
    fetchBoards().then((response) => {
      setBoards(response.data);
    }).catch((error) => {
      console.error("Error loading Shops:", error);
    });
  };

  const handleEditBoard = (id) => {
    navigate(`/dashboard/shop/modify/${id}`);
  };


  const handleDeleteBoard = (id) => {
    if (window.confirm('해당 지점을 삭제하시겠습니까?')) {
      deleteBoard(id).then(() => {
        loadBoards();
      }).catch((error) => {
        console.error("Error deleting toolSetting:", error);
      });
    }
  };

  // 선택된 항목을 삭제하는 함수
  const handleDeleteSelectedItems = () => {
    if (selectedItems.length === 0) {
      alert('지점을 선택해주세요.');
    } else {
      if (window.confirm('선택한 지점들을 삭제하시겠습니까?')) {
        Promise.all(selectedItems.map(id => deleteBoard(id))) // 모두 삭제
        .then(() => {
          loadBoards();
          setSelectedItems([]); // 선택된 항목 초기화
        })
        .catch(error => {
          console.error("Error deleting selected toolSettings:", error);
        });
      }
    }
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>지점 관리</div>
        <div className='util-wrap'>
          <div className='totalCnt'>Total {boards.length}</div>
          <div className='button-wrap'>
            <button onClick={handleDeleteSelectedItems} className='delete'>
                선택삭제
            </button>
            <Link to="/dashboard/shop/write">지점 등록</Link>
          </div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>선택</div>
            <div className='idx'>No</div>
            <div className='shop'>지점명</div>
            <div className='shop'>영문관리명</div>
            <div className='shop'>사업자번호</div>
            <div className='controll'>관리</div>
          </div>
          <BoardList boards={boards} onEdit={handleEditBoard} onDelete={handleDeleteBoard} setSelectedItems={setSelectedItems} />
        </div>
      </div>
    </>
  );
};

export default App;