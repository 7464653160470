import React, { useState, useEffect } from 'react';
import { applyCms, createCms } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import SignatureCapture from './SignatureCapture';
import Modal from './ConsentModal.js';

const ApplyCmsForm = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    price:'',
    phone_no:'',
    type:'0',
    bank_name: '',
    deposit_name: '',
    deposit_no:'',
    identity:'',
    card_month: '',
    card_year: '',
    signature: '',
    deposit_signature: '',
    join_date: '',
    withdrawal_day:'25'
  });
  const [loading, setLoading] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [customWithdrawalDay, setCustomWithdrawalDay] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignatureChange = (dataUrl) => {
    setFormData({ ...formData, signature: dataUrl });
  };

  const handleDepositSignatureChange = (dataUrl) => {
    setFormData({ ...formData, deposit_signature: dataUrl });
  };

  const handleWithdrawalDayChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, withdrawal_day: value });

    // '기타' 선택 시, 입력 필드 활성화
    if (value !== 'custom') {
      setCustomWithdrawalDay(''); // 기타가 아닐 때는 값을 초기화
    }
  };

  const handleCustomWithdrawalDayChange = (e) => {
    const value = e.target.value;
    setCustomWithdrawalDay(value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // '기타'를 선택했지만 숫자를 입력하지 않은 경우 경고창 띄우기
    if (formData.withdrawal_day === 'custom' && !customWithdrawalDay) {
      window.alert('출금일을 입력해주세요.');
      return;
    }

    if (!formData.signature) {
      window.alert('대표자의 서명이 필요합니다.');
      return;
    }

    // 상호명과 예금주가 다르면 예금주의 서명을 확인
    if (formData.user_name !== formData.deposit_name && !formData.deposit_signature) {
      window.alert('예금주의 서명이 필요합니다.');
      return;
    }

    if (!privacyAccepted) {
      alert('개인정보 처리방침에 동의해주세요.');
      return;
    }


    setLoading(true);

    const finalFormData = {
      ...formData,
      withdrawal_day: formData.withdrawal_day === 'custom' ? customWithdrawalDay : formData.withdrawal_day
    };

    try {
      const response = await applyCms(finalFormData);
      console.log(response);
      if (response.data.fileName) {

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace('T', ' ').slice(0, 16);
        const updatedFormData = { ...formData, join_date: formattedDate };
        const cmsData = {
          name: updatedFormData.user_name,
          phone_no: updatedFormData.phone_no,
          type: updatedFormData.type,
          identity: updatedFormData.identity,
          deposit_name: updatedFormData.deposit_name,
          deposit_no: updatedFormData.deposit_no,
          confirm_date: updatedFormData.join_date,
          down_link: response.data.fileName
        };
        createCms(cmsData);
        navigate('/cms/confirm', { state: { fileName: response.data.fileName }});
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  // 라벨과 placeholder를 납부 방법에 따라 동적으로 가져오는 객체
  const fieldLabels = {
    '0': { // CMS
      phoneMax: 11,
      identityLabel: '생년월일',
      identityPlaceholder: '생년월일 6자리 ex)900618',
      identityMax: 6,
      bankLabel: '은행명',
      bankPlaceholder: '은행명',
      depositLabel: '예금주명',
      depositPlaceholder: '예금주명',
      purNoLabel: '계좌번호',
      purNoPlaceholder: '계좌번호',
    },
    '1': { // 신용카드
      phoneMax: 11,
      identityLabel: '생년월일',
      identityPlaceholder: '생년월일 6자리 ex)900618',
      identityMax: 6,
      bankLabel: '카드사명',
      bankPlaceholder: '카드사명',
      depositLabel: '카드 소유자 이름',
      depositPlaceholder: '카드 소유자 이름',
      purNoLabel: '카드번호',
      purNoPlaceholder: '카드번호',
      cmMax:2,
      cyMax:2
    }
  };

  const currentLabels = fieldLabels[formData.type];

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-write.svg`} /></div>
          <div className='contents-wrap cms'>
            <form onSubmit={handleSubmit} className='login-form'>
              <p className='sub-title'>신청인 정보를 입력해주세요</p>
              <div className='consent-section cms'>
                <div className='desc-wrap'>
                  <p>
                    • 필수정보 입력 전, 아래 개인정보 처리방침을 확인해주세요.
                  </p>
                </div>
                <div className='agree'>
                  <label>
                    <input
                      type="checkbox"
                      checked={privacyAccepted}
                      onChange={() => setPrivacyAccepted(!privacyAccepted)}
                    />
                    개인정보 처리방침에 동의합니다.
                  </label>
                  <button type="button" className="viewMore" onClick={() => setShowPrivacyModal(true)}>전문보기</button>
                </div>
              </div>
              <div className='input-wrap'>
                <p className='label'>납부방법</p>
                <div className='radio-wrap'>
                  <label>
                    <input type="radio" name="type" value="0" checked={formData.type === '0'} onChange={handleChange} /> CMS{'('}계좌자동결제{')'}
                  </label>
                  <label>
                    <input type="radio" name="type" value="1" checked={formData.type === '1'} onChange={handleChange} /> 신용카드
                  </label>
                </div>
              </div>
              <div className='input-wrap'>
                <p className='label'>월 수수료 {'('}부가세 포함{')'}</p>
                <input type="number" name="price" inputmode="numeric" placeholder="월 수수료 (부가세 포함)" value={formData.price} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>출금일 선택</p>
                <div className='radio-wrap wide'>
                  <label>
                    <input type="radio" name="withdrawal_day" value="25" checked={formData.withdrawal_day === '25'} onChange={handleWithdrawalDayChange} /> 25일
                  </label>
                  <label>
                    <input type="radio" name="withdrawal_day" value="30" checked={formData.withdrawal_day === '30'} onChange={handleWithdrawalDayChange} /> 30일
                  </label>
                  <label>
                    <div className='radio-text'>
                      <input type="radio" name="withdrawal_day" value="custom" checked={formData.withdrawal_day === 'custom'} onChange={handleWithdrawalDayChange} /> 기타
                    </div>
                    {formData.withdrawal_day === 'custom' && (
                      <div className='etc-text'>
                        <input type="number" name="custom_withdrawal_day" value={customWithdrawalDay} onChange={handleCustomWithdrawalDayChange} placeholder="" min="1" max="31" /> 일
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className='input-wrap'>
                <p className='label'>상호명</p>
                <input type="text" name="user_name" placeholder="상호명" value={formData.user_name} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>휴대폰 번호</p>
                <input type="number" name="phone_no" inputmode="numeric" 
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                maxLength={currentLabels.phoneMax} placeholder="'-'하이픈 없이 숫자로 입력해주세요" value={formData.phone_no} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>{currentLabels.identityLabel}</p>
                <input type="number" name="identity" inputmode="numeric" 
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                maxLength={currentLabels.identityMax} placeholder={currentLabels.identityPlaceholder} value={formData.identity} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>{currentLabels.bankLabel}</p>
                <input type="text" name="bank_name" placeholder={currentLabels.bankPlaceholder} value={formData.bank_name} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>{currentLabels.depositLabel}</p>
                <input type="text" name="deposit_name" placeholder={currentLabels.depositPlaceholder} value={formData.deposit_name} onChange={handleChange} required />
              </div>
              <div className='input-wrap'>
                <p className='label'>{currentLabels.purNoLabel}</p>
                <input type="number" name="deposit_no" inputmode="numeric" placeholder={currentLabels.purNoPlaceholder} value={formData.deposit_no} onChange={handleChange} required />
              </div>
              {formData.type === '1' && (
                <div className='input-wrap'>
                  <p className='label'>카드 유효기간</p>
                  <div className='input-no'>
                    <input type="number" name="card_month" inputmode="numeric"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    maxLength={currentLabels.cmMax} placeholder='00' value={formData.card_month} onChange={handleChange} required />
                    <span>/</span>
                    <input type="number" name="card_year" inputmode="numeric"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    maxLength={currentLabels.cyMax} placeholder='00' value={formData.card_year} onChange={handleChange} required />
                  </div>
                </div>
              )}

              {/* 서명 캡처 추가 */}
              <div className='input-wrap column'>
                <p className='label'>대표자 서명</p>
                <SignatureCapture onChange={handleSignatureChange} />
              </div>

              {/* 상호명과 예금주가 다를 경우 예금주의 서명 */}
              {formData.user_name !== formData.deposit_name && (
                <div className='input-wrap column'>
                  <p className='label'>예금주 서명</p>
                  <SignatureCapture onChange={handleDepositSignatureChange} />
                </div>
              )}
              <button type="submit">신청</button>
            </form>
          </div>
          {loading && (
            <div className='loading-spinner'>
              <div className='spinner'></div>
              <p>신청중입니다.<br/>잠시만 기다려주세요</p>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={showPrivacyModal} onClose={() => setShowPrivacyModal(false)} title="개인정보 처리방침">
        <p className='title'>개인정보 수집 및 이용 동의</p>
        <div className='desc'>
        ◆ 수집 및 이용목적 : 효성CMS 자동이체를 통한 요금 수납 <br/><br/>
        ◆ 수집항목 : {'('}개인정보{')'} 성명, 생년월일, 연락처, 결제사명, 결제자명, 계좌번호, 카드번호, 유효기간, 휴대/유선전화번호 <br/>
        {'('}고유식별정보{')'} 주민등록번호, 단 휴대/유선전화결제에 한함<br/><br/>
        ◆ 보유 및 이용기간 : 수집/이용 동의일부터 자동이체 종료일{'('}해지일{')'}까지<br/>
          - 신청자는 개인정보의 수집 및 이용을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다
        </div>
        <p className='title'>개인정보 제3자 제공 동의</p>
        <div className='desc'>
        ◆ 개인정보를 제공받는 자: 효성에프엠에스㈜, 금융기관{'('}하단 신청가능은행 참조{')'}, 통신사{'('}SKT, KT LGU+, CJ헬로비전{')'}, 카드사{'('}BC, 국민, 외환, 삼성, 신한, 롯데, 하나SK, 현대 등{')'}, 결제대행사{'('}KG이니시스, KCP{')'} 등, 자세한 내용은 홈페이지 게시 {'('}www.efnc.co.kr / 제휴사 소개 메뉴 내{')'}<br/><br/>
        ◆ 개인정보를 제공받는 자의 이용 목적: 자동이체서비스 제공 및 자동이체 동의 사실 통지<br/><br/>
        ◆ 제공하는 개인정보의 항목<br/>
        {'('}개인정보{')'} 성명, 생년월일, 연락처, 결제사명, 결제자명, 계좌번호, 카드번호, 유효기간, 휴대/유선전화번호<br/>
        {'('}고유식별정보{')'} 주민등록번호, 단 휴대/유선전화결제에 한함.<br/><br/>
        ◆ 개인정보를 제공받는자의 개인정보 보유 및 이용기간: 동의일부터 자동이체의 종료일{'('}해지일{')'}까지. 단, 관계 법령에 의거 일정기간 동안 보관<br/><br/>
        ◆ 신청자는 개인정보에 대해 수납업체가 제 3자에 제공하는 것을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다
        </div>
      </Modal>
    </>
  );
};

export default ApplyCmsForm;