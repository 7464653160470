import React, { useState, useEffect } from 'react';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BoardList = ({ boards, onEdit, onDelete, setSelectedItems }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]); 
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = boards.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(boards.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    let updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id) // 선택 해제
      : [...selected, id]; // 선택 추가

    setSelected(updatedSelected);
    setSelectedItems(updatedSelected); // 부모에게 선택된 항목 전달
  };

  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((board, index) => (
            <li key={board.id}>
              <div className='idx'>
                <input
                  type='checkbox'
                  checked={selected.includes(board.id)}
                  onChange={() => handleCheckboxChange(board.id)}
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='shop'>{board.name}</div>
              <div className='shop'>{board.name_en}</div>
              <div className='shop'>{board.business_no}</div>
              <div className='controll'>
              <button onClick={() => onEdit(board.id)}>수정</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default BoardList;